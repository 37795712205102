import Hero from "./components/Hero/index";

function App() {
  return (
    <>
      <Hero />
    </>
  );
}

export default App;
